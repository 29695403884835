import  logo from "./images/logo.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
function Footer() {
    useEffect(() => {
        AOS.init({duration:1500});
    }, [])
    const today = new Date();
    const year = today.getFullYear();
    return (
        <section className="footer_one pt50 pb25">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-xl-7">
                        <div className="footer_about_widget text-start">
                            <div className="logo mb40 mb0-sm">
                                <img
                                    alt="header-logo.png"
                                    loading="lazy"
                                    width={100}
                                    height={45}
                                    decoding="async"
                                    data-nimg={1}
                                    className="logo2 img-fluid"
                                    style={{ color: "transparent" }}
                                    src= {logo}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-xl-5">
                        <div className="footer_menu_widget text-start text-md-end mt15">
                            <ul>
                                <li className="list-inline-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/About">About Us</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/Listing">Listings</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/blog">Blog</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/user-profile">User</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/service">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container pt80 pt20-sm pb70 pb0-sm">
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <div className="footer_about_widget">
                            <h5 className="title">OFFICE</h5>
                            <p>
                               Nairobi —<br />
                               Runda Fourways,
                                <br />
                                Kiambu Road
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <div className="footer_contact_widget">
                            <h5 className="title">NEED HELP</h5>
                            <div className="footer_phone">+254 701 289 079</div>
                            <p>james@ruewbiey.com</p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <div className="footer_contact_widget">
                            <h5 className="title">OPENING HOURS</h5>
                            <p>
                                Monday – Friday: 08:00AM – 07:00PM
                                <br />
                                Saturday: 09:00AM – 07:00PM
                                <br />
                                Sunday: Closed
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="footer_contact_widget">
                            <h5 className="title">KEEP IN TOUCH</h5>
                            <form className="footer_mailchimp_form">
                                <div className="wrapper">
                                    <div className="col-auto">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter your email..."
                                            required=""
                                        />
                                        <button type="submit">GO</button>
                                    </div>
                                </div>
                            </form>
                            <p>Get latest updates and offers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-8 col-xl-9">
                        <div className="copyright-widget mt5 text-start mb20-sm">
                            <p>
                                <a
                                    href=""
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Ruewbiey Motors
                                </a>{" "}
                                {/* */}© {/* */}{year}{/* */}. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3">
                        <div className="footer_social_widget text-start text-md-end">
                            <ul className="mb0">
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa-brands fa-whatsapp" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fab fa-twitter" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">
                                        <i className="fa-brands fa-facebook-f" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Footer;