import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../Top.css';
import cars from './cars';
import logo from "../images/logo.svg";


function ListingSingleV2({ setProgress }) {

  useEffect(() => {
    setProgress(25);
    setProgress(55);
    setTimeout(() => {
      setProgress(100);
    }, 1500);
  }, [])

  const { carsId } = useParams();

  const car = cars.find((cars) => cars.id === carsId)


  return (
    <>
      <section className="our-agent-single pb90 bt1 pt30 mt70-992">
        <div className="container">
          <div className="row mb30">
            <div className="col-xl-12">
              <div className="breadcrumb_content style2">
                <ol className="breadcrumb float-start">
                  <li className="breadcrumb-item">
                    <a href="../Listing">Listing</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Cars for Sale
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="row mb30">
            <div className="col-lg-7 col-xl-8">
              <div className="single_product_grid single_page1">
                <div className="sps_content">
                  <div className="thumb">
                    <div className="single_product">
                      <div className="single_item">
                        <div className="thumb">

                          <img
                            className="img-fluid"
                            src={car.image1}
                            alt="lsp5-v2.jpg"
                          />
                        </div>
                      </div>
                      <div className="overlay_icon">
                        <button
                          role="button"
                          className="video_popup_btn popup-img popup-youtube"
                        >
                          <span className="fa-solid fa-play" />
                          Video
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className="row">
                <div className="col-xxs-6 col-xs-3 col-sm-3 col-md-6">
                  <div className="sp5_small_img mb25">
                    <div className="thumb">
                      <div className="popup-img">
                        <img
                          alt="Rover1"
                          loading="lazy"
                          width={196}
                          height={158}
                          decoding="async"
                          data-nimg={1}
                          className="img-whp pointer"
                          srcSet={car.image2 + ' 1x,' + car.image2 + ' 2x'}
                          src={car.image2}
                          style={{
                            color: "transparent",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxs-6 col-xs-3 col-sm-3 col-md-6">
                  <div className="sp5_small_img mb25">
                    <div className="thumb">
                      <div className="popup-img">
                        <img
                          alt="Rover2"
                          loading="lazy"
                          width={196}
                          height={158}
                          decoding="async"
                          data-nimg={1}
                          className="img-whp pointer"
                          srcSet={car.image3 + ' 1x,' + car.image3 + ' 2x'}
                          src={car.image3}
                          style={{
                            color: "transparent",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxs-6 col-xs-3 col-sm-3 col-md-6">
                  <div className="sp5_small_img mb25">
                    <div className="thumb">
                      <div className="popup-img">
                        <img
                          alt="sp5s3.jpg"
                          loading="lazy"
                          width={196}
                          height={158}
                          decoding="async"
                          data-nimg={1}
                          className="img-whp pointer"
                          srcSet={car.image4 + ' 1x,' + car.image4 + ' 2x'}
                          src={car.image4}
                          style={{
                            color: "transparent",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxs-6 col-xs-3 col-sm-3 col-md-6">
                  <div className="sp5_small_img mb25">
                    <div className="thumb">
                      <div className="popup-img">
                        <img
                          alt="sp5s4.jpg"
                          loading="lazy"
                          width={196}
                          height={158}
                          decoding="async"
                          data-nimg={1}
                          className="img-whp pointer"
                          srcSet={car.image5 + ' 1x,' + car.image5 + ' 2x'}
                          src={car.image5}
                          style={{
                            color: "transparent",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxs-6 col-xs-3 col-sm-3 col-md-6">
                  <div className="sp5_small_img mb25">
                    <div className="thumb">
                      <div className="popup-img">
                        <img
                          alt="sp5s5.jpg"
                          loading="lazy"
                          width={196}
                          height={158}
                          decoding="async"
                          data-nimg={1}
                          className="img-whp pointer"
                          srcSet={car.image6 + ' 1x,' + car.image6 + ' 2x'}
                          src={car.image6}
                          style={{
                            color: "transparent",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxs-6 col-xs-3 col-sm-3 col-md-6">
                  <div className="sp5_small_img mb25">
                    <div className="thumb">
                      <div className="popup-img">
                        <img
                          alt="sp5s6.jpg"
                          loading="lazy"
                          width={196}
                          height={158}
                          decoding="async"
                          data-nimg={1}
                          className="img-whp pointer"
                          srcSet={car.image7 + ' 1x,' + car.image7 + ' 2x'}
                          src={car.image7}
                          style={{
                            color: "transparent",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                      <div className="overlay popup-img pe-none">
                        <span className="fa-solid fa-camera" />
                        <h5 className="title">Show All Photo</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb30">
            <div className="col-lg-7 col-xl-8">
              <div className="single_page_heading_content">
                <div className="car_single_content_wrapper">
                  <ul className="car_info mb20-md">
                    <li className="list-inline-item">
                      <a href="#">BRAND NEW - IN STOCK</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="flaticon-clock-1 vam" />1 years ago
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="flaticon-eye vam" />
                        13102
                      </a>
                    </li>
                  </ul>
                  <h2 className="title">{car.make}</h2>
                  <p className="para">
                    {car.shortDescription}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className="single_page_heading_content text-start text-lg-end">
                <div className="share_content">
                  <ul>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="flaticon-email" />
                        EMAIL
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="flaticon-printer" />
                        PRINT
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="flaticon-heart" />
                        SAVE
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="flaticon-share" />
                        SHARE
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="price_content">
                  <div className="price mt60 mb10 mt10-md">
                    <h3>
                      {car.price}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-xl-8">
              <div className="listing_single_description mt30">
                <h4 className="mb30">
                  Description{" "}
                  <span className="float-end body-color fz13">ID #9535</span>
                </h4>
                <p className="first-para">
                  {car.longDescription}
                </p>
              </div>
              <div className="user_profile_service">
                <div className="col-lg-12">
                  <h4 className="title">Features</h4>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-xl-6">
                    <h5 className="subtitle">Convenience</h5>
                  </div>
                  <div className="col-lg-6 col-xl-5">
                    <ul className="service_list">
                      <li>Heated Seats</li>
                      <li>Heated Steering Wheel</li>
                      <li>Navigation System</li>
                      <li>Power Liftgate</li>
                    </ul>
                  </div>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-xl-6">
                    <h5 className="subtitle">Entertainment</h5>
                  </div>
                  <div className="col-lg-6 col-xl-5">
                    <ul className="service_list">
                      <li>Apple CarPlay/Android Auto</li>
                      <li>Bluetooth</li>
                      <li>HomeLink</li>
                    </ul>
                  </div>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-xl-6">
                    <h5 className="subtitle">Exterior</h5>
                  </div>
                  <div className="col-lg-6 col-xl-5">
                    <ul className="service_list">
                      <li>Alloy Wheels</li>
                      <li>Sunroof/Moonroof</li>
                    </ul>
                  </div>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-xl-6">
                    <h5 className="subtitle">Safety</h5>
                  </div>
                  <div className="col-lg-6 col-xl-5">
                    <ul className="service_list">
                      <li>Backup Camera</li>
                      <li>Blind Spot Monitor</li>
                      <li>Brake Assist</li>
                      <li>LED Headlights</li>
                      <li>Stability Control</li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-12">
                    <a className="fz12 tdu color-blue" href="#">
                      View all features
                    </a>
                  </div>
                </div>
              </div>
              <div className="user_profile_location">
                <h4 className="title">Location</h4>
                <div className="property_sp_map mb40">
                  <div className="h400 bdrs8 map_in" id="map-canvas">
                    <iframe src="https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" />
                  </div>
                </div>
                <div className="upl_content d-block d-md-flex">
                  <p className="float-start fn-sm mb20-sm">
                    <span className="fas fa-map-marker-alt pr10 vam" />
                    Runda Fourways, Kiambu Road
                  </p>
                  <button className="btn location_btn">Get Direction</button>
                </div>
              </div>
              <div className="user_review_form">
                <div className="bsp_reveiw_wrt">
                  <h4 className="mt10">Write a Review</h4>
                  <form className="comments_form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Your Name"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Email"
                            type="email"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            rows={9}
                            placeholder="Message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-thm">
                          Send Your Review
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4">
              <div className="offer_btns">
                <div className="text-end">
                  <button className="btn btn-thm ofr_btn1 btn-block mt0 mb20">
                    <span className="flaticon-coin mr10 fz18 vam" />
                    Make an Offer Price
                  </button>
                  <button className="btn ofr_btn2 btn-block mt0 mb20">
                    <span className="flaticon-profit-report mr10 fz18 vam" />
                    View VIN Report
                  </button>
                </div>
              </div>
              <div className="opening_hour_widgets p30 mb30">
                <div className="wrapper">
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Make</div>
                      </div>
                      <span className="schedule">{car.make}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Model</div>
                      </div>
                      <span className="schedule">{car.model}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Color</div>
                      </div>
                      <span className="schedule">{car.color}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Drive Type</div>
                      </div>
                      <span className="schedule">{car.drive}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Transmission</div>
                      </div>
                      <span className="schedule">{car.transmission}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Condition</div>
                      </div>
                      <span className="schedule">{car.condition}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Year</div>
                      </div>
                      <span className="schedule">{car.year}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Mileage</div>
                      </div>
                      <span className="schedule">{car.mileage}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Fuel Type</div>
                      </div>
                      <span className="schedule">{car.fuel}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Engine Size</div>
                      </div>
                      <span className="schedule">{car.engine}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Doors</div>
                      </div>
                      <span className="schedule">{car.doors}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">Cylinders</div>
                      </div>
                      <span className="schedule">{car.cylinders}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="me-auto">
                        <div className="day">VIN</div>
                      </div>
                      <span className="schedule">2D456THA798700213GT21</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="sidebar_seller_contact">
                <div className="d-flex align-items-center mb30">
                  <div className="flex-shrink-0">
                    <img
                      alt="author.png"
                      loading="lazy"
                      width={60}
                      height={60}
                      decoding="async"
                      data-nimg={1}
                      className="mr-3 author_img w60"
                      src={logo}
                      style={{ color: "transparent" }}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mt0 mb5 fz16 heading-color fw600">
                      Reuwbiey Motors
                    </h5>
                    <p className="mb0 tdu">
                      <span className="flaticon-phone-call pr5 vam" />
                      +254 701 289 079
                    </p>
                  </div>
                </div>
                <h4 className="mb30">Contact Seller</h4>
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <input
                          className="form-control form_control"
                          placeholder="Name"
                          required=""
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <input
                          className="form-control form_control"
                          placeholder="Phone"
                          required=""
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <input
                          className="form-control form_control"
                          placeholder="Email"
                          required=""
                          type="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          rows={6}
                          required=""
                          defaultValue={
                            "I am interested in a price quote on this vehicle. Please contact me at your earliest convenience with your best price for this vehicle."
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-block btn-thm mt10 mb20"
                      >
                        Send Message
                      </button>
                      <button
                        type="submit"
                        className="btn btn-block btn-whatsapp mb0"
                      >
                        <span className="flaticon-whatsapp mr10 text-white" />
                        WhatsApp
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default ListingSingleV2;