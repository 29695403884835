import { Link } from 'react-router-dom';
import './Top.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from "./images/logo.svg";
import Top from './Top.jsx';
function header() {
    return (
        <>

            <Top />
            <style
                data-emotion="css 16jesut"
                data-s=""
                dangerouslySetInnerHTML={{
                    __html:
                        ".css-16jesut{position:relative;width:100%;}.css-16jesut >.ps-menu-button{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:50px;-webkit-text-decoration:none;text-decoration:none;color:inherit;box-sizing:border-box;cursor:pointer;padding-right:20px;padding-left:20px;}.css-16jesut >.ps-menu-button:hover{background-color:#f3f3f3;}"
                }}
            />
            <style
                data-emotion="css honxw6"
                data-s=""
                dangerouslySetInnerHTML={{
                    __html:
                        ".css-honxw6{display:inline-block;-webkit-transition:-webkit-transform 0.3s;transition:transform 0.3s;border-right:2px solid currentcolor;border-bottom:2px solid currentcolor;width:5px;height:5px;-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);}"
                }}
            />
            <style
                data-emotion="css 12w9als"
                data-s=""
                dangerouslySetInnerHTML={{
                    __html:
                        ".css-12w9als{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}"
                }}
            />
            <style
                data-emotion="css ewdv3l"
                data-s=""
                dangerouslySetInnerHTML={{
                    __html: ".css-ewdv3l{list-style-type:none;padding:0;margin:0;}"
                }}
            />
            <style
                data-emotion="css z5rm24"
                data-s=""
                dangerouslySetInnerHTML={{
                    __html:
                        ".css-z5rm24{display:none;overflow:hidden;z-index:999;-webkit-transition:height 300ms;transition:height 300ms;box-sizing:border-box;background-color:white;position:static!important;-webkit-transform:none!important;-moz-transform:none!important;-ms-transform:none!important;transform:none!important;}"
                }}
            />
            <style
                data-emotion="css 12vkui9"
                data-s=""
                dangerouslySetInnerHTML={{
                    __html:
                        ".css-12vkui9{display:inline-block;width:100%;position:relative;}.css-12vkui9 >.ps-menu-button{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:50px;-webkit-text-decoration:none;text-decoration:none;color:inherit;box-sizing:border-box;cursor:pointer;padding-right:20px;padding-left:40px;}.css-12vkui9 >.ps-menu-button:hover{background-color:#f3f3f3;}"
                }}
            />



            <header class="header-nav menu_style_home_one transparent main-menu">
                <nav>
                    <div className="container posr">
                        <div className="menu-toggle">
                            <button id="menu-btn">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <Link className="navbar_brand float-start dn-md" to ="/" >
                            <img
                                alt="header-logo.svg"
                                loading="lazy"
                                width={100}
                                height={4}
                                decoding="async"
                                data-nimg={1}
                                className="logo1 img-fluid"
                                src={logo}
                                style={{ color: "transparent" }}
                            />

                        </Link>
                        <ul id="respMenu" className="ace-responsive-menu text-end" data-menu-style="horizontal">

                            <li className="dropitem">
                                <Link to ="/Listing">
                                    <span className="title"> Our Stock in Kenya</span>
                                </Link>
                            </li>
                            <li className="dropitem">
                                <Link to ="">
                                    <span className="title">International Stock</span>
                                </Link>
                            </li>
                            <li className="dropitem">
                                <Link to ="/About">
                                    <span className="title">About</span>
                                </Link>
                            </li>
                            <li className="dropitem">
                                <Link to="/sell-your-car">
                                    <span className="title">Sell Your Car</span>
                                </Link>
                            </li>
                            <li className="dropitem">
                                <a href="">
                                    <span className="title">Affiliates</span>
                                    <span className="fa-solid fa-angle-down"></span>
                                </a>
                                <ul className="sub-menu">
                                    <li className="dropitem">
                                        <Link to="">Rubih Homes</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="add_listing">
                                <Link to="/Contact">Contact Us</Link>
                            </li>
                            <li
                                className="sidebar_panel"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                            >
                                <a className="sidebar_switch pt0" role="button">
                                    <span></span>
                                </a>
                            </li>
                        </ul>

                    </div>
                </nav>

            </header>
            <div className='stylehome1 h0'>
                <div className="mobile-menu">
                    <div className="header stylehome1">
                        <div className="mobile_menu_bar">
                            <a
                                className="menubar"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#mobileMenu"
                                aria-controls="mobileMenu"
                            >
                                <small>Menu</small>
                                <span />
                            </a>
                        </div>
                        <div className="mobile_menu_main_logo">
                            <img
                                alt="brand"
                                fetchpriority="high"
                                width={70}
                                height={45}
                                decoding="async"
                                data-nimg={1}
                                src={logo}
                                style={{ color: "transparent" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="offcanvas offcanvas-end mobile_menu-canvas"
                tabIndex={-1}
                id="mobileMenu"
                aria-labelledby="mobileMenuLabel"
                data-bs-scroll="true"
            >
                <div className="offcanvas-body">
                    <div className="pro-header">
                        <Link to="/">
                            <img
                                alt="brand"
                                fetchpriority="high"
                                width={90}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                src={logo}
                                style={{ color: "transparent" }}
                            />
                        </Link>
                        <div className="fix-icon" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="fa-solid fa-circle-xmark" />
                        </div>
                    </div>
                    <aside
                        data-testid="ps-sidebar-root-test-id"
                        className="ps-sidebar-root my-custom-class css-np5i73"
                        width="100%"
                    >
                        <div
                            data-testid="ps-sidebar-container-test-id"
                            className="ps-sidebar-container css-8485u"
                        >
                            <nav className="ps-menu-root css-vj11vy">
                                <ul className="css-ewdv3l">
                                    <li className="ps-menuitem-root ps-submenu-root  css-16jesut">
                                        <Link
                                            className="ps-menu-button"
                                            data-testid="ps-menu-button-test-id"
                                            tabIndex={0}
                                            to= "/Listing"
                                        >
                                            <span className="ps-menu-label css-12w9als">Our Stock in Kenya</span>
                                        </Link>
                                    </li>
                                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                                        <Link
                                            className="ps-menu-button"
                                            data-testid="ps-menu-button-test-id"
                                            tabIndex={0}
                                            to= "/Listing"
                                        >
                                            <span className="ps-menu-label css-12w9als">International Stock</span>
                                        </Link>
                                    </li>
                                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                                        <Link
                                            className="ps-menu-button"
                                            data-testid="ps-menu-button-test-id"
                                            tabIndex={0}
                                            to= "/About"
                                        >
                                            <span className="ps-menu-label css-12w9als">About</span>
                                        </Link>
                                    </li>
                                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                                        <Link
                                            className="ps-menu-button"
                                            data-testid="ps-menu-button-test-id"
                                            tabIndex={0}
                                            to= "/sell-your-car"
                                        >
                                            <span className="ps-menu-label css-12w9als">Sell Your Car</span>
                                        </Link>
                                    </li>
                                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                                        <a
                                            className="ps-menu-button"
                                            data-testid="ps-menu-button-test-id"
                                            tabIndex={0}
                                            href=""
                                        >
                                            <span className="ps-menu-label css-12w9als">Affiliates</span>
                                        </a>
                                    </li>
                                    <li className="ps-menuitem-root ps-submenu-root css-16jesut">
                                        <Link
                                            className="ps-menu-button"
                                            data-testid="ps-menu-button-test-id"
                                            tabIndex={0}
                                            to= "/Contact"
                                        >
                                            <span className="ps-menu-label css-12w9als">Contact Us</span>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </aside>
                    <div className="pro-footer mm-add-listing">
                        <div className="border-none">
                            <div className="mmenu-contact-info">
                                <span className="phone-num">
                                    <i className="fa-regular fa-map" />
                                    <a href="#">Kiambu Rd, Nairobi, Kenya</a>
                                </span>
                                <span className="phone-num">
                                    <i className="fa-solid fa-phone" />{" "}
                                    <a href="#">+254 701 289 079</a>
                                </span>
                                <span className="phone-num">
                                    <i className="fa-regular fa-clock" />{" "}
                                    <a href="#">Mon - Fri 8:00 - 18:00</a>
                                </span>
                            </div>
                            <div className="social-links">
                                <a href="#">
                                    <span className="fa-brands fa-whatsapp" />
                                </a>
                                <a href="#">
                                    <span className="fa-brands fa-facebook-f" />
                                </a>
                                <a href="#">
                                    <span className="fa-brands fa-x-twitter" />
                                </a>
                                <a href="#">
                                    <span className="fa-brands fa-instagram" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            


        </>

    );
}

export default header;