import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Top.css';
function top() {
    return (
        <>
            <div className="wrapper ovh">
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex={-1}
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="offcanvas-body">
                        <div className="siderbar_left_home pt20 active">
                            <button
                                className="sidebar_switch sidebar_close_btn float-end"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            >
                                X
                            </button>
                            <div className="footer_contact_widget mt100">
                                <h3 className="title">Quick contact info</h3>
                                <p>
                                    Some Words go here.
                                </p>
                            </div>
                            <div className="footer_contact_widget">
                                <h5 className="title">CONTACT</h5>
                                <div className="footer_phone">+254 701 289 079</div>
                                <p>james@ruewbiey.com</p>
                            </div>
                            <div className="footer_about_widget">
                                <h5 className="title">OFFICE</h5>
                                <p>
                               Nairobi —<br />
                                4ways Village,
                                <br />
                                Kiambu Road
                            </p>
                            </div>
                            <div className="footer_contact_widget">
                                <h5 className="title">OPENING HOURS</h5>
                                <p>
                                    Monday – Friday: 09:00AM – 09:00PM
                                    <br />
                                    Saturday: 09:00AM – 07:00PM
                                    <br />
                                    Sunday: Closed
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="header_top dn-992">
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-8 col-xl-7">
                                <div class="header_top_contact_opening_widget text-md-start">
                                    <ul className="mb0">
                                        <li className="list-inline-item">
                                            <a href="#">
                                                <span className="fa-solid fa-phone"></span>
                                                +254 701 289 079
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#">
                                                <span className="fa-regular fa-map"></span>
                                                Kiambu Rd, Nairobi, Kenya
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#">
                                                <span className="fa-regular fa-clock"></span>
                                                Mon - Fri 8:00 - 18:00
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-5">
                                <div class="header_top_social_widgets text-md-end">
                                    <ul className="mb0">
                                        <li className="list-inline-item">
                                            <a class="active" href="#">
                                                <span className="fa-brands fa-whatsapp"></span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a class="active" href="#">
                                                <span className="fa-brands fa-facebook-f"></span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a class="active" href="#">
                                                <span className="fa-brands fa-x-twitter"></span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a class="active" href="#">
                                                <span className="fa-brands fa-instagram"></span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#contact">Login</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default top;