import React, { useEffect } from "react";
import { useState } from "react";

class Content extends React.Component {
    constructor(props) {
        super(props);
        const JSX = (
            <div className="form_grid">
            <div className="wrapper">
              <h3 className="title mb20">Fill Out Form Below</h3>
              <form className="contact_form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name*</label>
                      <input
                        className="form-control"
                        placeholder="First Name"
                        required=""
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name*</label>
                      <input
                        className="form-control"
                        placeholder="last name"
                        required=""
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Email*</label>
                      <input
                        className="form-control email"
                        placeholder="youremail@gmail.com"
                        required=""
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        placeholder="+254 700 000 000"
                        required=""
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Message</label>
                      <textarea
                        name="form_message"
                        className="form-control"
                        rows={6}
                        placeholder="Message"
                        required=""
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group mb0">
                      <button type="submit" className="btn btn-thm">
                        Get In Touch
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
        this.state = {
            comments: JSX,
            title: "Sell Your Car"
        }
    }
    saveDataContentDefault = () => {
        const JSX = (
            <div className="form_grid">
            <div className="wrapper">
            <h3 className="title mb20">Fill Out Form Below</h3>
              <form className="contact_form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">First Name*</label>
                      <input
                        className="form-control"
                        placeholder="First Name"
                        required=""
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Last Name*</label>
                      <input
                        className="form-control"
                        placeholder="last name"
                        required=""
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Email*</label>
                      <input
                        className="form-control email"
                        placeholder="youremail@gmail.com"
                        required=""
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        placeholder="+254 700 000 000"
                        required=""
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Message</label>
                      <textarea
                        name="form_message"
                        className="form-control"
                        rows={6}
                        placeholder="Message"
                        required=""
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group mb0">
                      <button type="submit" className="btn btn-thm">
                        Get In Touch
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
        this.setState
            ({

                title: "Sell Your Car Form",
                comments: JSX
            })
    }
    saveDataContent = () => {

        this.setState
            ({

                title: "Information We Collect",
                comments: ""

            })
    }
    saveDataContent2 = () => {
        this.setState
            ({

                title: "Our Terms & Conditions",
                comments: ""

            })
    }
    saveDataContent3 = () => {
        this.setState
            ({

                title: "Your Privacy",
                comments: ""

            })
    }


    render() {
        return (
            <>
                <div className="col-md-8 col-xl-9">
                    <div className="terms_condition_grid">
                        <div
                            className="react-tabs__tab-panel"
                            role="tabpanel"
                            id="panel:r0:0"
                            aria-labelledby="tab:r0:0"
                        >
                        </div>
                        <div className="grids mb60">
                            <h4 className="title">{this.state.title}</h4>
                            <p className="mb25">
                                {this.state.comments}
                            </p>

                        </div>

                    </div>
                </div>
                <div className="col-md-4 col-xl-3">
                    <div className="terms_condition_widget">
                        <div className="widget_list">
                            <h5 className="title">Navigation</h5>
                            <ul className="list_details" role="tablist">
                                <li
                                    className="single-list react-tabs__tab--selected"
                                    role="tab"
                                    id="tab:r0:0"
                                    aria-selected="true"
                                    aria-disabled="false"
                                    aria-controls="panel:r0:0"
                                    data-rttab="true"
                                    onClick={this.saveDataContentDefault}
                                >
                                    Form
                                </li>
                                <li
                                    className="single-list react-tabs__tab--selected"
                                    role="tab"
                                    id="tab:r0:0"
                                    aria-selected="true"
                                    aria-disabled="false"
                                    aria-controls="panel:r0:0"
                                    data-rttab="true"
                                    onClick={this.saveDataContent}
                                >
                                    Information We Collect
                                </li>
                                <li
                                    className="single-list"
                                    role="tab"
                                    id="tab:r0:1"
                                    aria-selected="false"
                                    aria-disabled="false"
                                    aria-controls="panel:r0:1"
                                    data-rttab="true"
                                    onClick={this.saveDataContent2}
                                >
                                    Our Terms & Conditions
                                </li>
                                <li
                                    className="single-list"
                                    role="tab"
                                    id="tab:r0:3"
                                    aria-selected="false"
                                    aria-disabled="false"
                                    aria-controls="panel:r0:3"
                                    data-rttab="true"
                                    onClick={this.saveDataContent3}
                                >
                                    Your Privacy
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}


function SellYourCar({ setProgress }) {
    const [buttonText, setButtonText] = useState('Submit');
    useEffect(() => {
        setProgress(25);
        setProgress(55);
        setTimeout(() => {
            setProgress(100);
        }, 1500);
    }, [])

    return (
        <>
            <section className="inner_page_breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="breadcrumb_content">
                                <h2 className="breadcrumb_title">Sell You Car</h2>
                                <p className="subtitle">Sell Your Car</p>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <a href="">Sell Your Car</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-terms pb90">
                <div className="container">
                    <div className="row">
                        <div className="react-tabs" data-rttabs="true">
                            <div className="row">
                                {<Content />}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default SellYourCar;