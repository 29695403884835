import React, { useEffect } from "react";
import compact from "./images/compact.png";
import convertible from "./images/convertible.png";
import coupe from "./images/coupe.png";
import sedan from "./images/sedan.png";
import suv from "./images/suv.png";
import james from "./images/james.jpeg";
import { Link } from 'react-router-dom';


function About({ setProgress }) {
  useEffect(() => {
    setProgress(25);
    setProgress(55);
    setTimeout(() => {
      setProgress(100);
    }, 1500);
  }, [])

  const comp = "COMPACT"
  const sed = "SEDAN"
  const su = "SUV"
  const conv = "CONVERTIBLE"
  const cou = "COUPE"
  return (
    <>
      <section className="inner_page_breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb_content">
                <h2 className="breadcrumb_title">About Us</h2>
                <p className="subtitle">About Us</p>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <a href="">About Us</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section pb10 mb10">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about_thumb mb30-md">
                <img
                  alt="1.jpg"
                  fetchpriority="high"
                  width={636}
                  height={667}
                  decoding="async"
                  data-nimg={1}
                  className="thumb1"
                  srcSet={james + ' 1x,' + james + ' 2x'}
                  src={james}
                  style={{ color: "transparent", objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="about_content">
                <h2 className="title">Ruewbiey Motors Ltd</h2>
                <p className="mb30">
                  Ruewbiey Motors is a premier car dealership offering a wide selection of both foreign and locally used vehicles.
                </p>
                <p className="mb30">
                  Our mission is to provide customers with a seamless, hassle-free car buying and selling experience, backed by a comprehensive suite of automotive services.
                </p>
                <a className="btn btn-thm about-btn" href="Contact">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-chose pt10 pb0"
        style={{ backgroundColor: "#eef2f6" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-title text-center">
                <h2>Why Choose Us?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-sm-6 col-lg-3 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="why_chose_us home7_style">
                <div className="icon style1">
                  <span className="fa-solid fa-coins" />
                </div>
                <div className="details">
                  <h5 className="title">Hire Purchase, Bank/Asset Finance Arrangements and Motor Vehicle Insurance</h5>
                  <p>
                    We facilitate vehicle financing options for customers, allowing you to drive away in your
                    new vehicle with a down payment as low as 20% of the car's value. Additionally, we offer tailored
                    motor vehicle insurance solutions to protect your investment.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-6 col-lg-3 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="why_chose_us home7_style">
                <div className="icon style1">
                  <span className="fa-solid fa-ship" />
                </div>
                <div className="details">
                  <h5 className="title">Car Sales and Imports</h5>
                  <p>
                    We offer an extensive inventory of both foreign and locally used vehicles to meet diverse preferences.
                    Additionally, our custom import services allow you to order specific vehicles directly from
                    international markets, ensuring you get the exact car you desire.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-6 col-lg-3 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="why_chose_us home7_style">
                <div className="icon style2">
                  <span className="fa-solid fa-cart-flatbed-suitcase" />
                </div>
                <div className="details">
                  <h5 className="title">Trade-In Option</h5>
                  <p>
                    Take advantage of our trade-in option to make buying your next car even easier.
                    Trade in your current vehicle and apply its value toward a new one from our
                    inventory. This cost-effective solution reduces the overall cost of your new car and simplifies the buying process.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-6 col-lg-3 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="why_chose_us home7_style">
                <div className="icon style3">
                  <span className="fa-solid fa-building-columns" />
                </div>
                <div className="details">
                  <h5 className="title">Comprehensive Vehicle Services</h5>
                  <p>
                    We provide advanced car tracking and fleet management systems, along with expert consultancy on purchasing, selling,
                    and maintaining vehicles. Our services ensure efficient operations and informed decisions for all your vehicle needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-team pb90 pt30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center">
                <h2>Our Team</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="home1_popular_listing home2_style aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="listing_item_4grid_slider dots_none">
                  <div className="swiper swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div className="swiper-wrapper">
                      <div
                        className="swiper-slide swiper-slide-active"
                        style={{ width: 309, marginRight: 20 }}
                        data-swiper-slide-index={0}
                      >
                        <div className="item">
                          <div className="team_member">
                            <div className="thumb">
                              <img
                                alt="Dianne Russell"
                                loading="lazy"
                                width={313}
                                height={375}
                                decoding="async"
                                data-nimg={1}
                                className="img-fluid"
                                srcSet={james + ' 1x,' + james + ' 2x'}
                                src={james}
                                style={{ color: "transparent", objectFit: "cover" }}
                              />
                            </div>
                            <div className="details">
                              <h5 className="title">James N. Gitau </h5>
                              <p className="para">Director</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-next"
                        style={{ width: 309, marginRight: 20 }}
                        data-swiper-slide-index={1}
                      >
                        <div className="item">
                          <div className="team_member">
                            <div className="thumb">
                              <img
                                alt="Devon Lane"
                                loading="lazy"
                                width={313}
                                height={375}
                                decoding="async"
                                data-nimg={1}
                                className="img-fluid"
                                srcSet="/_next/image?url=%2Fimages%2Fteam%2F2.jpg&w=384&q=75 1x, /_next/image?url=%2Fimages%2Fteam%2F2.jpg&w=640&q=75 2x"
                                src="/_next/image?url=%2Fimages%2Fteam%2F2.jpg&w=640&q=75"
                                style={{ color: "transparent", objectFit: "cover" }}
                              />
                            </div>
                            <div className="details">
                              <h5 className="title">Devon Lane</h5>
                              <p className="para">Nursing Assistant</p>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div
                        className="swiper-slide"
                        data-swiper-slide-index={4}
                        style={{ width: 309, marginRight: 20 }}
                      >
                        <div className="item">
                          <div className="team_member">
                            <div className="thumb">
                              <img
                                alt="Dianne Russell"
                                loading="lazy"
                                width={313}
                                height={375}
                                decoding="async"
                                data-nimg={1}
                                className="img-fluid"
                                srcSet="/_next/image?url=%2Fimages%2Fteam%2F1.jpg&w=384&q=75 1x, /_next/image?url=%2Fimages%2Fteam%2F1.jpg&w=640&q=75 2x"
                                src="/_next/image?url=%2Fimages%2Fteam%2F1.jpg&w=640&q=75"
                                style={{ color: "transparent", objectFit: "cover" }}
                              />
                            </div>
                            <div className="details">
                              <h5 className="title">Dianne Russell</h5>
                              <p className="para">Dog Trainer</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="car-category mobile_space bgc-f9  pb100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center">
                <h2>Popular Makes</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="category_item">
                <div className="thumb">
                  <img
                    alt="1.png"
                    loading="lazy"
                    width={150}
                    height={58}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent", objectFit: "cover" }}
                    src={compact}
                  />
                </div>
                <div className="details">
                  <p className="title">
                  <Link  to = {`body-style/${comp}`}>Compact </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="category_item">
                <div className="thumb">
                  <img
                    alt="2.png"
                    loading="lazy"
                    width={150}
                    height={58}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent", objectFit: "cover" }}
                    src={sedan}
                  />
                </div>
                <div className="details">
                  <p className="title">
                  <Link  to = {`body-style/${sed}`}>Sedan </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="category_item">
                <div className="thumb">
                  <img
                    alt="3.png"
                    loading="lazy"
                    width={150}
                    height={58}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent", objectFit: "cover" }}
                    src={suv}
                  />
                </div>
                <div className="details">
                  <p className="title">
                  <Link  to = {`body-style/${su}`}>SUV </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="category_item">
                <div className="thumb">
                  <img
                    alt="4.png"
                    loading="lazy"
                    width={150}
                    height={58}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent", objectFit: "cover" }}
                    src={convertible}
                  />
                </div>
                <div className="details">
                  <p className="title">
                  <Link  to = {`body-style/${conv}`}>Convertible </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={500}
            >
              <div className="category_item">
                <div className="thumb">
                  <img
                    alt="5.png"
                    loading="lazy"
                    width={150}
                    height={58}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent", objectFit: "cover" }}
                    src={coupe}
                  />
                </div>
                <div className="details">
                  <p className="title">
                  <Link  to = {`body-style/${cou}`}>Coupe </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default About;