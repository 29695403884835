import React, { useEffect } from "react";
function Contact ({setProgress}){
    useEffect(() => {
        setProgress(25);
        setProgress(55);
        setTimeout(() => {
           setProgress(100);
        }, 1500);
       }, [])
return(
    <>
    <section className="our-contact p0 mt0 mt70-992">
  <div className="container-fluid p0">
    <div className="row">
      <div className="col-lg-12">
        <div className="h600 map_in">
          <iframe src="https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"/>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="our-contact  bgc-f9">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="contact_icon_box mb50">
          <div className="details">
            <h3 className="title">Contact Details</h3>
            <p>
              Nairobi — <br /> Runda Fourways
              <br /> Kiambu Road
            </p>
            <h4 className="subtitle">
              <a href="tel:+254 701 289 079">+254 701 289 079</a>
            </h4>
            <p>
              <a href="mailto:james@ruewbiey.com">james@ruewbiey.com</a>
            </p>
            <div className="footer_social_widget">
              <ul className="mb0">
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa-brands fa-whatsapp" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="form_grid">
          <div className="wrapper">
            <h3 className="title mb20">Contact Form</h3>
            <form className="contact_form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">First Name*</label>
                    <input
                      className="form-control"
                      placeholder="First Name"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Last Name*</label>
                    <input
                      className="form-control"
                      placeholder="last name"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email*</label>
                    <input
                      className="form-control email"
                      placeholder="youremail@gmail.com"
                      required=""
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone</label>
                    <input
                      className="form-control"
                      placeholder="+254 700 000 000"
                      required=""
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Message</label>
                    <textarea
                      name="form_message"
                      className="form-control"
                      rows={6}
                      placeholder="Message"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group mb0">
                    <button type="submit" className="btn btn-thm">
                      Get In Touch
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
)
}

export default Contact;