
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import cars from './cars';

const Listing =({setProgress}) => {
  useEffect(() => {
    setProgress(25);
    setProgress(55)
    setTimeout(() => {
        setProgress(100);
    }, 1500);
  }, [])


    return(
        <>
<section className="advance_search_menu_sectn bgc-thm2 pt20 pb0 mt70-992 filter-style_two">
  <div className="container">
    <div className="row">
      <div className="col-12 col-sm-4 col-lg-2">
        <div className="advance_search_style">
          <select className="form-select show-tick">
            <option>Condition</option>
            <option>Most Recent</option>
            <option>Recent</option>
            <option>Best Selling</option>
            <option>Old Review</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-4 col-lg-2">
        <div className="advance_search_style">
          <select className="form-select show-tick">
            <option>Select Makes</option>
            <option>Toyota</option>
            <option>Mazda</option>
            <option>Land Rover</option>
            <option>BMW</option>
            <option>Nissan</option>
            <option>Honda</option>
            <option>Suzuki</option>
            <option>Mitsubishi</option>
            <option>Subaru</option>
            <option>Volkswagen</option>
            <option>Mercedes</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-4 col-lg-2">
        <div className="advance_search_style">
          <select className="form-select show-tick">
            <option>Select Models</option>
            <option>A3 Sportback</option>
            <option>A4</option>
            <option>A6</option>
            <option>Q5</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-4 col-lg-2">
        <div className="advance_search_style">
          <select className="form-select show-tick">
            <option>Select Type</option>
            <option>Convertible</option>
            <option>Coupe</option>
            <option>Hatchback</option>
            <option>Sedan</option>
            <option>SUV</option>
          </select>
        </div>
      </div>
      <div className="col col-sm-4 col-lg-2">
        <div className="advance_search_style">
          <a
            className="advance_dd_btn d-inline-flex"
            href="#collapseAdvanceSearch"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="collapseAdvanceSearch"
          >
            <span className="fa-solid fa-gear" /> Advanced
          </a>
        </div>
      </div>
      <div className="col col-sm-4 col-lg-2">
        <div className="advance_search_style">
          <a className="btn search_btn btn-thm" href="">
            <span className="fa-solid fa-magnifying-glass" /> Search
          </a>
        </div>
      </div>
    </div>
    <div className="collapse" id="collapseAdvanceSearch">
      <div className="row bgc-thm2">
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Year</option>
              <option>1967</option>
              <option>1990</option>
              <option>2000</option>
              <option>2002</option>
              <option>2005</option>
              <option>2010</option>
              <option>2015</option>
              <option>2020</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Fuel Type</option>
              <option>Diesel</option>
              <option>Electric</option>
              <option>Hybrid</option>
              <option>Petrol</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Transmission</option>
              <option>Autometic</option>
              <option>Manual</option>
              <option>Semi-Autometic</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Doors</option>
              <option>2 Doors</option>
              <option>3 Doors</option>
              <option>4 Doors</option>
              <option>5 Doors</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Interior Color</option>
              <option>Black</option>
              <option>Beige</option>
              <option>Brown</option>
              <option>Red</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Exterior Color</option>
              <option>Black</option>
              <option>Beige</option>
              <option>Brown</option>
              <option>Red</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Cylinders</option>
              <option>4</option>
              <option>6</option>
              <option>8</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <select className="form-select show-tick">
              <option>Listing Status</option>
              <option>Active</option>
              <option>Pending</option>
              <option>Disable</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <input
              className="form-control form_control"
              type="number"
              placeholder="Min. Mileage"
            />
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <input
              className="form-control form_control"
              type="number"
              placeholder="Max. Mileage"
            />
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <div className="advance_search_style">
            <input
              className="form-control form_control"
              type="number"
              placeholder="VIN number"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="advance_search_style">
            <div className="uilayout_range">
              <h6 className="ass_price_title text-white text-start">Price</h6>
              <div>
                <div aria-disabled="false" className="input-range">
                  <span className="input-range__label input-range__label--min">
                    <span className="input-range__label-container" />
                  </span>
                  <div className="input-range__track input-range__track--background">
                    <div
                      style={{
                        left: "21.052631578947366%",
                        width: "52.63157894736842%"
                      }}
                      className="input-range__track input-range__track--active"
                    />
                    <span
                      className="input-range__slider-container"
                      style={{
                        position: "absolute",
                        left: "21.052631578947366%"
                      }}
                    >
                      <span className="input-range__label input-range__label--value">
                        <span className="input-range__label-container" />
                      </span>
                      <div
                        aria-valuemax={15000}
                        aria-valuemin={1000}
                        aria-valuenow={5000}
                        className="input-range__slider"
                        draggable="false"
                        role="slider"
                        tabIndex={0}
                      />
                    </span>
                    <span
                      className="input-range__slider-container"
                      style={{
                        position: "absolute",
                        left: "73.68421052631578%"
                      }}
                    >
                      <span className="input-range__label input-range__label--value">
                        <span className="input-range__label-container" />
                      </span>
                      <div
                        aria-valuemax={20000}
                        aria-valuemin={5000}
                        aria-valuenow={15000}
                        className="input-range__slider"
                        draggable="false"
                        role="slider"
                        tabIndex={0}
                      />
                    </span>
                  </div>
                  <span className="input-range__label input-range__label--max">
                    <span className="input-range__label-container" />
                  </span>
                </div>
                <span id="slider-range-value1">${/* */}5000</span>
                <span id="slider-range-value2">${/* */}15000</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-6 ">
          <h6 className="font-600 ass_price_title text-white text-start mb-3">
            Features
          </h6>
          <div className="advance_search_style">
            <div className="ui_kit_checkbox text-start me-3">
              <div className="df mb20">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Adaptive Cruise Control (6,676)
                </label>
              </div>
              <div className="df mb20">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck2"
                />
                <label className="custom-control-label" htmlFor="customCheck2">
                  Cooled Seats (9,784)
                </label>
              </div>
              <div className="df mb20">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck3"
                />
                <label className="custom-control-label" htmlFor="customCheck3">
                  Keyless Start (9,784)
                </label>
              </div>
            </div>
            <div className="ui_kit_checkbox text-start">
              <div className="df mb20">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck4"
                />
                <label className="custom-control-label" htmlFor="customCheck4">
                  Navigation System (9,784)
                </label>
              </div>
              <div className="df mb20">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck5"
                />
                <label className="custom-control-label" htmlFor="customCheck5">
                  Remote Start (9,784)
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="inner_page_breadcrumb style2 inner_page_section_spacing">
  <div className="container">
    <div className="row">
      <div className="col-xl-12">
        <div className="breadcrumb_content style2">
          <h2 className="breadcrumb_title">Used Vehicles For Sale</h2>
          <p className="subtitle">Listing</p>
          <ol className="breadcrumb fn-sm mt15-sm">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Vehicles in Kenya
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="our-listing pt0 bgc-f9 pb30-991">
  <div className="container">
    <div className="row">
      <div className="listing_filter_row db-767">
        <div className="col-md-4">
          <div className="page_control_shorting left_area tac-sm mb30-767 mt15">
            <p>
              We found <span className="heading-color fw600">5</span> Cars
              available for you
            </p>
          </div>
        </div>
        <div className="col-md-8">
          <div className="page_control_shorting right_area text-end tac-xsd">
            <ul>
              <li className="list-inline-item short_by_text listone">
                Sort by:
              </li>
              <li className="list-inline-item listwo">
                <select className="form-select show-tick">
                  <option>Date: newest First</option>
                  <option>Most Recent</option>
                  <option>Recent</option>
                  <option>Best Selling</option>
                  <option>Old Review</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="row">
    { /*One Car Item Card*/}
    {
               cars && cars.map((cars) =>(
               <>
               <div className="col-sm-6 col-lg-4 col-xl-3">
        <div className="car-listing">
          <div className="thumb">
            <div className="tag">FEATURED</div>
            <img
              alt="Volvo XC90 - 2023"
              fetchpriority="high"
              width={284}
              height={183}
              decoding="async"
              data-nimg={1}
              style={{
                color: "transparent",
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
              srcSet={ cars.image1 + ' 1x,' + cars.image1 + ' 2x'}
              src={cars.image1}
            />            
            <div className="thmb_cntnt3">
              <ul className="mb0">
                <li className="list-inline-item">
                  <a href="#">
                    <span className="fa-solid fa-code-compare" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <span className="fa-solid fa-heart" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="details">
            <div className="wrapper">
              <h5 className="price"> {cars.price}</h5>
              <h6 className="title">
                
                <Link to ={`ListingSingleV2/${cars.id}`} >{cars.make} - {cars.year}</Link>
              </h6>
              <div className="listign_review">
                <ul className="mb0">
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">4.7</a>
                  </li>
                  <li className="list-inline-item">
                    ({/* */}{/* */} Safety Rating)
                  </li>
                </ul>
              </div>
            </div>
            <div className="listing_footer">
              <ul className="mb0">
                <li className="list-inline-item">
                  <span className="fa-solid fa-road me-2" />
                  {cars.mileage}
                </li>
                <li className="list-inline-item">
                  <span className="fa-solid fa-gas-pump me-2" />
                  {cars.fuel}
                </li>
                <li className="list-inline-item">
                  <span className="fa-solid fa-car me-2" />
                  {cars.transmission}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
               </>
               ))
               }

    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="mbp_pagination mt10">
          <ul className="page_navigation">
            <li role="button" className="page-item">
              <span className="page-link">
                <span className="fa fa-arrow-left" />
              </span>
            </li>
            <li role="button" className="page-item active">
              <span className="page-link">1</span>
            </li>
            <li role="button" className="page-item ">
              <span className="page-link">2</span>
            </li>
            <li role="button" className="page-item ">
              <span className="page-link">3</span>
            </li>
            <li role="button" className="page-item">
              <span className="page-link">
                <span className="fa fa-arrow-right" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

</>
    );

}
export default Listing;