import './App.css';
import React, { useState } from 'react';
import LoadingBar from 'react-top-loading-bar'
import Header from './Header.js';
import Body from './Body.jsx';
import About from './About.jsx';
import Contact from './Contact.jsx';
import Listing from './listing/Listing.jsx';
import ListingSingleV2 from './listing/listing-single-v2.jsx';
import Footer from './Footer.jsx';
import SellYourCar from './sell-your-car.jsx';
import ScrollToTop from "react-scroll-to-top";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Brand from './Brand.js';
import BodyStyle from './body-style.js';
class Click extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: true
        };
        this.toggleVisiblity = this.toggleVisiblity.bind(this);
    }

    toggleVisiblity() {
        this.setState(state => ({
            visibility: !state.visibility
        }));

    }

    render() {
        if (this.state.visibility) {
            return (
                <div>
                    <button className='b1' onClick={this.toggleVisiblity}> Click Me </button>
                    <h1>Now You See Me</h1>
                </div>
            );
        } else {
            return (
                <div>
                    <button className='b2' onClick={this.toggleVisiblity}> Click Me 2</button>
                    <h1>Now You See Me 2</h1>
                </div>
            );
        }
    };

}

const App = () =>  {
    const [progress, setProgress] = useState(0);
    return (
        <>
            <Router>
                <div>
                    <div>
                        <Header />
                        <LoadingBar
                            color='#f5c34b'
                            progress={progress}
                            onLoaderFinished={() => setProgress(0)}
                        />
                    </div>
                    <div>
                        <ScrollToTop
                            smooth
                            style={{ color: "#f5c34b" }}
                            component={<i className="fas fa-arrow-up" />} />

                    </div>
                    <div className="cont">
                        <Switch>
                            <Route exact path="/">
                                <Body setProgress = {setProgress} />
                            </Route>
                            <Route path="/Listing" element = {<Listing />}>
                                <Listing setProgress = {setProgress}/>
                            </Route>
                            <Route path="/ListingSingleV2/:carsId" element = {<listing-single-v2 /> }>
                                <ListingSingleV2 setProgress = {setProgress} />
                            </Route>
                            <Route path = "/About" element = {<About />}>
                                <About setProgress={setProgress} />
                            </Route>
                            <Route path = "/Contact" element = {<Contact />}>
                                <Contact setProgress={setProgress} />
                            </Route>
                            <Route path = "/sell-your-car" element = {<sell-your-car />}>
                                <SellYourCar setProgress={setProgress} />
                            </Route>
                            <Route path="/Brand/:carsBrand" element = {<Brand/> }>
                                <Brand setProgress = {setProgress} />
                            </Route>
                            <Route path="/body-style/:carsBody" element = {<body-style /> }>
                                <BodyStyle setProgress = {setProgress} />
                            </Route>
                        </Switch>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </Router>
        </>
    );
}

export default App;
