import Rover1 from '../images/land-rover-1.jpeg';
import Rover2 from '../images/land-rover-2.jpeg';
import Rover3 from '../images/land-rover-3.jpeg';
import Rover5 from '../images/land-rover-5.jpeg';
import Rover6 from '../images/land-rover-6.jpeg';
import Rover8 from '../images/land-rover-8.jpeg';

import prado from "./img/prado6.jpeg"
import prado1 from "./img/prado1.jpeg"
import prado2 from "./img/prado5.jpeg"
import prado3 from "./img/prado4.jpeg"
import prado4 from "./img/prado6.jpeg"
import prado5 from "./img/prado2.jpeg"

import axio from "./img/axio3.jpeg"
import axio1 from "./img/axio2.jpeg"
import axio2 from "./img/axio1.jpeg"
import axio3 from "./img/axio4.jpeg"
import axio4 from "./img/axio5.jpeg"
import axio5 from "./img/axio7.jpeg"

import sienta from "./img/sienta7.jpeg"
import sienta1 from "./img/sienta3.jpeg"
import sienta2 from "./img/sienta2.jpeg"
import sienta3 from "./img/sienta5.jpeg"
import sienta4 from "./img/sienta6.jpeg"
import sienta5 from "./img/sienta4.jpeg"

import legacy from "./img/legacy4.jpeg"
import legacy1 from "./img/legacy5.jpeg"
import legacy2 from "./img/legacy6.jpeg"
import legacy3 from "./img/legacy1.jpeg"
import legacy4 from "./img/legacy3.jpeg"
import legacy5 from "./img/legacy2.jpeg"

import kca from "./img/KCA1.jpeg"
import kca1 from "./img/KCA2.jpeg"
import kca2 from "./img/KCA3.jpeg"
import kca3 from "./img/KCA4.jpeg"
import kca4 from "./img/KCA5.jpeg"
import kca5 from "./img/KCA6.jpeg"
import kca8 from "./img/KCA9.jpeg"

import kdq from "./img/KDQ6.jpeg"
import kdq1 from "./img/KDQ1.jpeg"
import kdq2 from "./img/KDQ2.jpeg"
import kdq3 from "./img/KDQ3.jpeg"
import kdq4 from "./img/KDQ5.jpeg"
import kdq5 from "./img/KDQ7.jpeg"
import kdq6 from "./img/KDQ8.jpeg"

import kcv from "./img/KCV1.jpeg"
import kcv1 from "./img/KCV2.jpeg"
import kcv2 from "./img/KCV3.jpeg"
import kcv3 from "./img/KCV5.jpeg"
import kcv4 from "./img/KCV6.jpeg"
import kcv5 from "./img/KCV7.jpeg"
import kcv6 from "./img/KCV3.jpeg"

import kdg from "./img/KDG1.jpeg"
import kdg1 from "./img/KDG3.jpeg"
import kdg2 from "./img/KDG4.jpeg"
import kdg3 from "./img/KDG5.jpeg"
import kdg4 from "./img/KDG6.jpeg"
import kdg5 from "./img/KDG8.jpeg"
import kdg6 from "./img/KDG2.jpeg"

import auris from "./img/AURIS9.jpeg"
import auris1 from "./img/AURIS2.jpeg"
import auris2 from "./img/AURIS5.jpeg"
import auris3 from "./img/AURIS7.jpeg"
import auris4 from "./img/AURIS8.jpeg"
import auris5 from "./img/AURIS9.jpeg"
import auris6 from "./img/AURIS1.jpeg"

import kdj from "./img/KDJ1.jpeg"
import kdj1 from "./img/KDJ2.jpeg"
import kdj2 from "./img/KDJ3.jpeg"
import kdj3 from "./img/KDJ4.jpeg"
import kdj4 from "./img/KDJ5.jpeg"
import kdj5 from "./img/KDJ6.jpeg"
import kdj6 from "./img/KDJ7.jpeg"

import kcn from "./img/KCN7.jpeg"
import kcn1 from "./img/KCN3.jpeg"
import kcn2 from "./img/KCN4.jpeg"
import kcn3 from "./img/KCN5.jpeg"
import kcn4 from "./img/KCN6.jpeg"
import kcn5 from "./img/KCN2.jpeg"
import kcn6 from "./img/KCN2.jpeg"

import kde from "./img/KDE1.jpeg"
import kde1 from "./img/KDE2.jpeg"
import kde2 from "./img/KDE3.jpeg"
import kde3 from "./img/KDE4.jpeg"
import kde4 from "./img/KDE5.jpeg"
import kde5 from "./img/KDE6.jpeg"
import kde6 from "./img/KDE1.jpeg"

import kce from "./img/KCE6.jpeg"
import kce1 from "./img/KCE7.jpeg"
import kce2 from "./img/KCE5.jpeg"
import kce3 from "./img/KCE4.jpeg"
import kce4 from "./img/KCE3.jpeg"
import kce5 from "./img/KCE2.jpeg"
import kce6 from "./img/KCE1.jpeg"

import kda from "./img/KDA8.jpeg"
import kda1 from "./img/KDA6.jpeg"
import kda2 from "./img/KDA3.jpeg"
import kda3 from "./img/KDA4.jpeg"
import kda4 from "./img/KDA2.jpeg"
import kda5 from "./img/KDA9.jpeg"
import kda6 from "./img/KDA10.jpeg"

import kct from "./img/KCT6.jpeg"
import kct1 from "./img/KCT2.jpeg"
import kct2 from "./img/KCT1.jpeg"
import kct3 from "./img/KCT3.jpeg"
import kct4 from "./img/KCT4.jpeg"
import kct5 from "./img/KCT5.jpeg"
import kct6 from "./img/KCT6.jpeg"

import kcx from "./img/KCX5.jpeg"
import kcx1 from "./img/KCX1.jpeg"
import kcx2 from "./img/KCX2.jpeg"
import kcx3 from "./img/KCX3.jpeg"
import kcx4 from "./img/KCX4.jpeg"
import kcx5 from "./img/KCX6.jpeg"
import kcx6 from "./img/KCX7.jpeg"

import kdl from "./img/KDL6.jpeg"
import kdl1 from "./img/KDL2.jpeg"
import kdl2 from "./img/KDL3.jpeg"
import kdl3 from "./img/KDL4.jpeg"
import kdl4 from "./img/KDL2.jpeg"
import kdl5 from "./img/KDL5.jpeg"
import kdl6 from "./img/KDL7.jpeg"

import kcj from "./img/KCJ3.jpeg"
import kcj1 from "./img/KCJ2.jpeg"
import kcj2 from "./img/KCJ1.jpeg"
import kcj3 from "./img/KCJ5.jpeg"
import kcj4 from "./img/KCJ4.jpeg"
import kcj5 from "./img/KCJ1.jpeg"
import kcj6 from "./img/KCJ3.jpeg"

import kbs from "./img/KBS6.jpeg"
import kbs1 from "./img/KBS7.jpeg"
import kbs2 from "./img/KBS5.jpeg"
import kbs3 from "./img/KBS8.jpeg"
import kbs4 from "./img/KBS9.jpeg"
import kbs5 from "./img/KBS1.jpeg"
import kbs6 from "./img/KBS2.jpeg"

import noah from "./img/Noah2.jpeg"
import noah1 from "./img/Noah1.jpeg"
import noah2 from "./img/Noah3.jpeg"
import noah3 from "./img/Noah4.jpeg"
import noah4 from "./img/Noah5.jpeg"
import noah5 from "./img/Noah6.jpeg"
import noah6 from "./img/Noah3.jpeg"



export default [
    {
        id: "1",
        brand: "Land Rover",
        body: "SUV",
        price: "Ksh 3,550,000",
        shortDescription: "2011 Land Rover Discovery 4 SDV6 HSE",
        longDescription: "2011 Land Rover Discovery 4 SDV6 HSE, silver colour. 3000cc SDV6 diesel engine, 8 speed automatic transmission with steering mounted paddy shifts, 4WD with terrain response system, height control, low transfer case mode, cool box, sorround cameras, triple sunroof and moonroof, cruise control, daytime running lights, heated steering wheel, seven leather seats, premium Harman Kordon entertainment system, 20inch wheels, 9 way powered front seats, heated and cooled seats among other extras.",
        make: "LAND ROVER DISCOVERY",
        model: "Discovery 4 SDV6 HSE",
        color: "Gray",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2011",
        mileage: "148,000 Kms",
        fuel: "Diesel",
        engine: "5.2L",
        doors: "5",
        cylinders: "",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: Rover8,
        image2: Rover2,
        image3: Rover3,
        image4: Rover1,
        image5: Rover5,
        image6: Rover6,
        image7: Rover8
    },

    {
        id: "2",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 3,850,000",
        shortDescription: "2013 Toyota Land Cruiser Prado with 2018 facelift",
        longDescription: "Terms accepted for our stock: Asset finance, trade in and, hire purchase for 2016 and above models. We also buy locally used cars as well as sell on behalf. Every purchase comes with a free of charge full tank of fuel and Ruewbiey Motors/Rubiy Homes hoodie",
        make: "TOYOTA LAND CRUISER",
        model: "PRADO",
        color: "White",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2013",
        mileage: "109,000 Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,700",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: prado,
        image2: prado1,
        image3: prado2,
        image4: prado3,
        image5: prado4,
        image6: prado5,
        image7: prado
    },

    {
        id: "3",
        brand: "Toyota",
        body: "SEDAN",
        price: "Kshs 1,250,000",
        shortDescription: "2013 Toyota Axio",
        longDescription: "Terms accepted for our stock: Asset finance, trade in and, hire purchase for 2016 and above models. We also buy locally used cars as well as sell on behalf. Every purchase comes with a free of charge full tank of fuel and Ruewbiey Motors/Rubiy Homes hoodie",
        make: "TOYOTA AXIO",
        model: "AXIO",
        color: "Grey",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2013",
        mileage: "113,000 Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "1,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: axio,
        image2: axio1,
        image3: axio2,
        image4: axio3,
        image5: axio4,
        image6: axio5,
        image7: axio
    },

    {
        id: "4",
        brand: "Toyota",
        body: "COMPACT",
        price: "Kshs 820,000",
        shortDescription: "2013 Toyota Sienta",
        longDescription: "Terms accepted for our stock: Asset finance, trade in and, hire purchase for 2016 and above models. We also buy locally used cars as well as sell on behalf. Every purchase comes with a free of charge full tank of fuel and Ruewbiey Motors/Rubiy Homes hoodie",
        make: "TOYOTA SIENTA",
        model: "SIENTA",
        color: "Purple",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2013",
        mileage: "131,000 Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "1,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: sienta,
        image2: sienta1,
        image3: sienta2,
        image4: sienta3,
        image5: sienta4,
        image6: sienta5,
        image7: sienta
    },

    {
        id: "5",
        brand: "Subaru",
        body: "COMPACT",
        price: "Kshs 2,600,000",
        shortDescription: "2016 Subaru Legacy B4 BN9",
        longDescription: "Subaru Legacy B4 BN9 Model / Year 2016 /Unregistered / 2500cc/ Pearl white in colour/ 80,000kms of mileage/ Eyesight features /Lane assist tech/ Full leather seats /Heated Front and Rear seats/Smart key entry /Idle-stop option /Downhill Assist Control /Multifunctional steering /Si - Drive/Traction controls /Rear camera/ Xenon headlights /Fog lights/Parking sensors.",
        make: "SUBARU LEGACY",
        model: "Legacy B4 BN9",
        color: "Pearl white",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Foreign Used",
        year: "2016",
        mileage: "85,000 Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "1,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: legacy,
        image2: legacy1,
        image3: legacy2,
        image4: legacy3,
        image5: legacy4,
        image6: legacy5,
        image7: legacy
    },

    {
        id: "6",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 1,700,000",
        shortDescription: "2007 Toyota Hiace 7L",
        longDescription: " KCA 2007 Toyota Hiace 7L Highroof,automatic transmission ,2500cc 2KD Diesel Engine,Privately used,Low mileage,Original paint.",
        make: "TOYOTA HIACE",
        model: "7L",
        color: "Grey",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2007",
        mileage: "158,279 Kms",
        fuel: "Diesel",
        engine: "",
        doors: "5",
        cylinders: "2,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kca,
        image2: kca1,
        image3: kca2,
        image4: kca3,
        image5: kca4,
        image6: kca5,
        image7: kca8
    },

    {
        id: "7",
        brand: "Nissan",
        body: "SUV",
        price: "Kshs 2,650,000",
        shortDescription: "2017 Nissan Xtrail T32",
        longDescription: "2017 Nissan Xtrail T32,automatic,2000cc petrol,leather seats,360° Camera,Powered boot,Eco mode,Traction control,Idle-stop,360° Parking sensors,Cruise control,Heated Front seats.",
        make: "NISSAN XTRAIL",
        model: "T32",
        color: "Grey",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Foreign Used",
        year: "2017",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,000",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kdq,
        image2: kdq1,
        image3: kdq2,
        image4: kdq3,
        image5: kdq4,
        image6: kdq5,
        image7: kdq6
    },

    {
        id: "8",
        brand: "Honda",
        body: "SUV",
        price: "Kshs 2,000,000",
        shortDescription: "2012 Honda crv",
        longDescription: "2012 Honda crv,automatic,2000cc petrol,multifunctional steering,cruise controls,reverse camera,new set of tires.",
        make: "HONDA",
        model: "CRV",
        color: "Black",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2012",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,000",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kcv,
        image2: kcv1,
        image3: kcv2,
        image4: kcv3,
        image5: kcv4,
        image6: kcv5,
        image7: kcv6
    },

    {
        id: "9",
        brand: "Toyota",
        body: "COMPACT",
        price: "Kshs 1,400,000",
        shortDescription: "2014 Toyota Wish",
        longDescription: "014 Toyota Wish,automatic transmission,1800cc valvematic petrol engine,7 neat black fabric seats,keyless entry.",
        make: "TOYOTA",
        model: "Wish",
        color: "Grey",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2014",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "1,800",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kdg,
        image2: kdg1,
        image3: kdg2,
        image4: kdg3,
        image5: kdg4,
        image6: kdg5,
        image7: kdg6
    },
    {
        id: "10",
        brand: "Toyota",
        body: "COMPACT",
        price: "Kshs 1,300,000",
        shortDescription: "2014 Toyota Auris",
        longDescription: "2014 Toyota Auris,automatic transmission,1500cc petrol engine,Steering controls,Xenon headlights,Traction controls,Alloy rims,Keyless entry.",
        make: "TOYOTA",
        model: "Auris",
        color: "Grey",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2014",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "1,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: auris,
        image2: auris1,
        image3: auris2,
        image4: auris3,
        image5: auris4,
        image6: auris5,
        image7: auris6
    },
    {
        id: "11",
        brand: "Toyota",
        body: "COMPACT",
        price: "Kshs 1,500,000",
        shortDescription: " 2015 Toyota Fielder WXB",
        longDescription: " 2015 Toyota Fielder WXB,automatic transmission,1500cc petrol engine,Half leather Seats,Keyless,Steering controls,Sport mode,Traction controls,Reverse camera.",
        make: "TOYOTA FIELDER",
        model: "Fielder WXB",
        color: "Pearl white",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2015",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "1,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kdj,
        image2: kdj1,
        image3: kdj2,
        image4: kdj3,
        image5: kdj4,
        image6: kdj5,
        image7: kdj6
    },
    {
        id: "12",
        brand: "Toyota",
        body: "COMPACT",
        price: "Kshs 1,350,000",
        shortDescription: "2010 Toyota Hiace 7L",
        longDescription: "2010 Toyota Hiace 7L,automatic transmission,2000cc petrol engine,Tour van.",
        make: "TOYOTA HIACE",
        model: "7L",
        color: "Pearl white",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2010",
        mileage: "Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,000",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kcn,
        image2: kcn1,
        image3: kcn2,
        image4: kcn3,
        image5: kcn4,
        image6: kcn5,
        image7: kcn6
    },
    {
        id: "13",
        brand: "Mercedes",
        body: "SEDAN",
        price: "Kshs 2,900,000",
        shortDescription: "2015 MERCEDES BENZ C200 W205",
        longDescription: "2000cc turbocharged petrol engine. 350 NM of torque. 184 HP of power. 7 speed 7G-Tronic automatic transmission. Heated front seats. Multifunctional tilt & telescopic steering wheel. Black full leather seats. Premium surround sound system. Mercedes Distronic cruise control. Tyre pressure monitoring system.",
        make: "MERCEDES BENZ",
        model: "C200 W205",
        color: "Black",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Foreign Used",
        year: "2015",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,000",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kde,
        image2: kde1,
        image3: kde2,
        image4: kde3,
        image5: kde4,
        image6: kde5,
        image7: kde6
    },
    {
        id: "14",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 3,650,000",
        shortDescription: "2008 Toyota Landcruiser V8",
        longDescription: "2008 Toyota Landcruiser V8,automatic,4700cc Petrol,4wd, 7 Leather seats,Android radio,Crawl control,Parking sensors,Seat warmers Reverse camera.",
        make: "TOYOTA LANDCRUISER",
        model: "Landcruiser V8",
        color: "Black",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2008",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "4,700",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kce,
        image2: kce1,
        image3: kce2,
        image4: kce3,
        image5: kce4,
        image6: kce5,
        image7: kce6
    },
    {
        id: "15",
        brand: "Subaru",
        body: "COMPACT",
        price: "Kshs 1,400,000",
        shortDescription: "2014 SUBARU LEGACY BRM",
        longDescription: "2,500cc Naturally Aspirated engine. 235 NM of torque. 173 HP of power. Subaru linear tronic CVT transmission. Eyesight adaptive cruise control. Symmetrical AWD. Steering wheel mounted paddle shifts. Keyless entry. Front power seats. Driver memory seat. Front heated seats. Electric parking brake. Multifunctional steering wheel. Auto start stop idling feature. Auto turn on and off headlights. Aftermarket sound system with rear entertainment. Anti - collision brake assist. DVD player with bluetooth connectivity. Headlight washers. 5 black half leather seats. Alloy wheels. Fog lights. Low mileage 113,000kms only. Ready documents. ",
        make: "SUBARU LEGACY",
        model: "Legacy BRM",
        color: "Pearl white",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2014",
        mileage: "113,000 Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kda,
        image2: kda1,
        image3: kda2,
        image4: kda3,
        image5: kda4,
        image6: kda5,
        image7: kda6
    },
    {
        id: "16",
        brand: "Subaru",
        body: "COMPACT",
        price: "Kshs 1,250,000",
        shortDescription: "2012 Subaru Legacy",
        longDescription: "Transmission: Automatic. Year of manufacture:2012. Engine rating (cc): 1500. Fuel type: Petrol. Extras: Alloy wheels, fog lights, after market music, rear entertainment screens, black fabric seats, reverse camera, eye sight technology.",
        make: "SUBARU LEGACY",
        model: "Legacy",
        color: "Pearl white",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2012",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "1,500",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kct,
        image2: kct1,
        image3: kct2,
        image4: kct3,
        image5: kct4,
        image6: kct5,
        image7: kct6
    },
    {
        id: "17",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 4,500,000",
        shortDescription: "2013 Toyota Landcruiser Prado VXL",
        longDescription: "Engine rating (cc): 3000. Fuel type: Diesel. Extras:Sunroof, 4 wheel drive, Crawl control, Height control, Diff lock Seat warmers, Downhill Assist Control, heated mirrors, powered steering, cruise controls, 7 Full Leather seats reverse camera, full option model",
        make: "TOYOTA LANDCRUISER",
        model: "Landcruiser Prado VXL",
        color: "Brown",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2013",
        mileage: " Kms",
        fuel: "Diesel",
        engine: "",
        doors: "5",
        cylinders: "3,000",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kcx,
        image2: kcx1,
        image3: kcx2,
        image4: kcx3,
        image5: kcx4,
        image6: kcx5,
        image7: kcx6
    },

    {
        id: "18",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 2,680,000",
        shortDescription: "2015 Toyota Hiace 7L (Box)",
        longDescription: "3000cc 1KD, Diesel Engine Automatic transmission Privately used Low mileage Original paint Bank Finance accepted Trade-in accepted",
        make: "TOYOTA HIACE",
        model: "Hiace 7L (Box)",
        color: "Pearl white",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2015",
        mileage: " Kms",
        fuel: "Diesel",
        engine: "",
        doors: "5",
        cylinders: "3,000",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kdl,
        image2: kdl1,
        image3: kdl2,
        image4: kdl3,
        image5: kdl4,
        image6: kdl5,
        image7: kdl6
    },

    {
        id: "19",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 1,650,000",
        shortDescription: "2009 Toyota Rav4",
        longDescription: "2400cc 4 wheel drive Eco mode Fabric seats Cruise controls Rear camera Keyless entry Body kits.",
        make: "TOYOTA",
        model: "Rav4",
        color: "Black",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2009",
        mileage: " Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,400",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kcj,
        image2: kcj1,
        image3: kcj2,
        image4: kcj3,
        image5: kcj4,
        image6: kcj5,
        image7: kcj6
    },

    {
        id: "20",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 1,500,000",
        shortDescription: "2005 Toyota Land Cruiser Prado J120 V6",
        longDescription: "ansmission: Automatic. Engine rating (cc) : 3400. Fuel type: Petrol. Extras: Alloy wheels, fog lights, reverse camera, functional air conditioning system. Mileage: 160,000 km. Number of seats: 7, grey fabric. Ready documents. Buy and drive  no mechanical issues.",
        make: "TOYOTA LANDCRUISER",
        model: "Land Cruiser Prado J120 V6",
        color: "Grey",
        drive: "4WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2005",
        mileage: "160,000 Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "3,400",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: kbs,
        image2: kbs1,
        image3: kbs2,
        image4: kbs3,
        image5: kbs4,
        image6: kbs5,
        image7: kbs6
    },

    {
        id: "21",
        brand: "Toyota",
        body: "SUV",
        price: "Kshs 630,000",
        shortDescription: "2007 Toyota Noah",
        longDescription: "Transmission: Automatic. Year: 2007. Extras: Alloy wheels, fog lights, reverse camera, functional air conditioning system. Mileage: 158,000 km. Number of seats: 7, grey fabric. Ready documents. Buy and drive - no mechanical issues.",
        make: "TOYOTA",
        model: "Toyota Noah",
        color: "Grey",
        drive: "2WD",
        transmission: "Automatic",
        condition: "Locally Used",
        year: "2007",
        mileage: "158,000 Kms",
        fuel: "Petrol",
        engine: "",
        doors: "5",
        cylinders: "2,000",
        location: "https://www.google.com/maps/embed/v1/place?q=runda+fourways&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
        image1: noah,
        image2: noah1,
        image3: noah2,
        image4: noah3,
        image5: noah4,
        image6: noah5,
        image7: noah6
    },
    
    

]
