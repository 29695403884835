import './Body.css';
import React, { useEffect } from "react";
import compact from "./images/compact.png";
import convertible from "./images/convertible.png";
import coupe from "./images/coupe.png";
import sedan from "./images/sedan.png";
import suv from "./images/suv.png";
import toyota from "./images/brands/toyota.svg";
import mazda from "./images/brands/mazda.svg";
import bmw from "./images/brands/bmw.svg";
import nissan from "./images/brands/nissan.svg";
import honda from "./images/brands/honda.svg";
import suzuki from "./images/brands/suzuki.svg"
import mitsubishi from "./images/brands/mitsubishi.svg"
import subaru from "./images/brands/subaru.svg"
import vw from "./images/brands/volkswagen.svg"
import merc from "./images/brands/mercedes.svg"
import audi from "./images/brands/audi.svg"
import rover from "./images/brands/rover.svg"
import hero from "./images/hero.jpg"
import down from "./images/down.gif"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/css/all.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

function Body({ setProgress }) {
    useEffect(() => {
        AOS.init({ duration: 1500 });
    }, [])
    useEffect(() => {
        setProgress(20);
        setProgress(55);
        setTimeout(() => {
            setProgress(100);
        }, 1500);
    }, [])

    const toyotaLink = "Toyota";
    const mazdaLink = "Mazda";
    const bmwLink = "bmw";
    const nissanLink = "Nissan";
    const hondaLink = "Honda";
    const suzukiLink = "Suzuki";
    const mitsuLink = "Mitsubishi";
    const subLink = "Subaru";
    const vwLink = "Volkswagen";
    const mercLink = "Mercedes";
    const audiLink = "Audi";
    const roverLink = "Land Rover";
    const comp = "COMPACT"
    const sed = "SEDAN"
    const su = "SUV"
    const conv = "CONVERTIBLE"
    const cou = "COUPE"
    return (
        <>
            <section className="home-one bg-home1 pb100">
                <div className="container">
                    <div className="row posr">
                        <div className="col-lg-10 m-auto">
                            <div className="home_content home1_style">
                                <div className="home-text text-center mb30">
                                    <h2 className="title">
                                        <span className="aminated-object1">
                                            <img
                                                alt="img"
                                                fetchpriority="high"
                                                width={110}
                                                height={14}
                                                decoding="async"
                                                data-nimg={1}
                                                className="objects"
                                                src="/images/title-bottom-border.svg"
                                                style={{ color: "transparent", objectFit: "contain" }}
                                            />
                                        </span>
                                        <h1 className='main-banner'>Ruewbiey Motors</h1>
                                        <span className="aminated-object2">
                                            <img
                                                alt="img"
                                                fetchpriority="high"
                                                width={500}
                                                height={220}
                                                decoding="async"
                                                data-nimg={1}
                                                className="objects"
                                                src={hero}
                                                style={{ color: "transparent", objectFit: "contain" }}
                                            />
                                        </span><br />
                                        Find Your <strong>Dream</strong> Car
                                    </h2>
                                    <p className="para">Guranteed hassle-free car buying and selling experience!!</p>
                                </div>
                                <div className='filter_buttons'>
                                    <Link className="button-47" to="/Listing">Our Stock in Kenya</Link>
                                    <Link className="button-47" to ="/International-stock">International Stock</Link>
                                    <Link className="button-47" to ="sell-your-car">Sell your Vehicle</Link>
                                </div>
                                <span className="aminated-object3">
                                    <img
                                        alt="img"
                                        fetchpriority="high"
                                        width={50}
                                        height={50}
                                        decoding="async"
                                        data-nimg={1}
                                        className="objects"
                                        src={down}
                                        style={{ color: "transparent", objectFit: "contain" }}
                                    />
                                </span><br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="logo-category car-category mobile_logo_category bgc-fc pb100 pt0">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl "
                        >
                            <div className="logo_item">
                            
                                <Link className="thumb" to={`Brand/${toyotaLink}`}>
                                    <img
                                        alt="toyota"
                                        loading="lazy"
                                        width={70}
                                        height={75}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={toyota}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${mazdaLink}`}>
                                    <img
                                        alt="mazda"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={mazda}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${bmwLink}`}>
                                    <img
                                        alt="bmw"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={bmw}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${nissanLink}`}>
                                    <img
                                        alt="nissan"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={nissan}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${hondaLink}`}>
                                    <img
                                        alt="honda"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={honda}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${suzukiLink}`}>
                                    <img
                                        alt="suzuki"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={suzuki}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${mitsuLink}`}>
                                    <img
                                        alt="mitsubishi"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={mitsubishi}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${subLink}`}>
                                    <img
                                        alt="subaru"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={subaru}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${vwLink}`}>
                                    <img
                                        alt="vw"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={vw}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${mercLink}`}>
                                    <img
                                        alt="Mercedes"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={merc}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${audiLink}`}>
                                    <img
                                        alt="audi"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={audi}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                        >
                            <div className="logo_item">
                                <Link className="thumb" to={`Brand/${roverLink}`}>
                                    <img
                                        alt="rover"
                                        loading="lazy"
                                        width={70}
                                        height={70}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={rover}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="car-category mobile_space bgc-f9  pb100">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <div className="category_item">
                                <div className="thumb">
                                    <img
                                        alt="1.png"
                                        loading="lazy"
                                        width={150}
                                        height={58}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={compact}
                                    />
                                </div>
                                <div className="details">
                                    <p className="title">
                                        <Link  to = {`body-style/${comp}`}>Compact </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="category_item">
                                <div className="thumb">
                                    <img
                                        alt="2.png"
                                        loading="lazy"
                                        width={150}
                                        height={58}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={sedan}
                                    />
                                </div>
                                <div className="details">
                                    <p className="title">
                                    <Link  to = {`body-style/${sed}`}>Sedan </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={300}
                        >
                            <div className="category_item">
                                <div className="thumb">
                                    <img
                                        alt="3.png"
                                        loading="lazy"
                                        width={150}
                                        height={58}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={suv}
                                    />
                                </div>
                                <div className="details">
                                    <p className="title">
                                    <Link  to = {`body-style/${su}`}>SUV </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={400}
                        >
                            <div className="category_item">
                                <div className="thumb">
                                    <img
                                        alt="4.png"
                                        loading="lazy"
                                        width={150}
                                        height={58}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={convertible}
                                    />
                                </div>
                                <div className="details">
                                    <p className="title">
                                    <Link  to = {`body-style/${conv}`}>Convertible </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-4 col-lg col-xl aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={500}
                        >
                            <div className="category_item">
                                <div className="thumb">
                                    <img
                                        alt="5.png"
                                        loading="lazy"
                                        width={150}
                                        height={58}
                                        decoding="async"
                                        data-nimg={1}
                                        style={{ color: "transparent", objectFit: "cover" }}
                                        src={coupe}
                                    />
                                </div>
                                <div className="details">
                                    <p className="title">
                                    <Link  to = {`body-style/${cou}`}>Coupe </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-chose pt50 pb90">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-title text-center">
                                <h2>Why Choose Us?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-sm-6 col-lg-3 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <div className="why_chose_us home7_style">
                                <div className="icon style1">
                                    <span className="fa-solid fa-coins" />
                                </div>
                                <div className="details">
                                    <h5 className="title">Hire Purchase, Bank/Asset Finance Arrangements and Motor Vehicle Insurance</h5>
                                    <p>
                                        We facilitate vehicle financing options for customers, allowing you to drive away in your 
                                        new vehicle with a down payment as low as 20% of the car's value. Additionally, we offer tailored 
                                        motor vehicle insurance solutions to protect your investment.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-sm-6 col-lg-3 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <div className="why_chose_us home7_style">
                                <div className="icon style1">
                                    <span className="fa-solid fa-ship" />
                                </div>
                                <div className="details">
                                    <h5 className="title">Car Sales and Imports</h5>
                                    <p>
                                        We offer an extensive inventory of both foreign and locally used vehicles to meet diverse preferences.
                                        Additionally, our custom import services allow you to order specific vehicles directly from
                                        international markets, ensuring you get the exact car you desire.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-sm-6 col-lg-3 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={300}
                        >
                            <div className="why_chose_us home7_style">
                                <div className="icon style2">
                                    <span className="fa-solid fa-cart-flatbed-suitcase" />
                                </div>
                                <div className="details">
                                    <h5 className="title">Trade-In Option</h5>
                                    <p>
                                    Take advantage of our trade-in option to make buying your next car even easier.
                                    Trade in your current vehicle and apply its value toward a new one from our 
                                    inventory. This cost-effective solution reduces the overall cost of your new car and simplifies the buying process.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-sm-6 col-lg-3 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={400}
                        >
                            <div className="why_chose_us home7_style">
                                <div className="icon style3">
                                    <span className="fa-solid fa-building-columns" />
                                </div>
                                <div className="details">
                                    <h5 className="title">Comprehensive Vehicle Services</h5>
                                    <p>
                                    We provide advanced car tracking and fleet management systems, along with expert consultancy on purchasing, selling, 
                                    and maintaining vehicles. Our services ensure efficient operations and informed decisions for all your vehicle needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Body;